:root {
  --amplify-primary-color: #2693ff;
  --amplify-primary-tint: #ff7359;
  --amplify-primary-shade: #e0573e;
  /* --amplify-font-family: 'Roboto', ‘Helvetica’, ‘Helvetica Neue Light’, ‘Helvetica Neue’, ‘Arial’, ‘sans-serif’; */
}

#root {
  display:inline-block;
  width:100%;
  min-height: 100vh; 
  display: flex; 
  flex-direction: column;
}

.ui.header {
  font-family:'Roboto', 'Helvetica', 'Helvetica Neue Light', 'Helvetica Neue', 'Arial', 'sans-serif' !important;
}

html {
  scroll-behavior: smooth;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.fill-white-svg {
  position: absolute;
  bottom:-1px;
  left:0;
  background-color:transparent;
  fill:white;
}


.fill-svg {
  position: absolute;
  bottom:-1px;
  left:0;
  background-color:transparent;
  fill:rgb(38, 147, 255);
}

.fill-svg-drk-blue {
  position: absolute;
  bottom:-1px;
  left:0;
  background-color:transparent;
  fill:#013153;
}



p {
  word-wrap: normal;
  margin:0;
}

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.content {
  padding-top:30px;
  width: 75%;
  margin-left: auto;
  margin-right: auto;
  text-align: justify;
  min-width: 400px;
}

.topBarMenu {
  background-color: white !important; 
  position: sticky;
  top:0;
  margin:0 !important;
  z-index: 1;
  height:65px;
 }

 .menuButton {
  color:black;
  padding-left:10px;
  padding-right:10px;
  cursor: pointer;
  height:100%;
  padding-top:1em;
 }

 
 .menuButton:hover {
  font-weight: 600;
  border-bottom:3px solid #4DC6E7;
}

 .ui.button.homeButton {
  font-weight: 300 !important;
  color:white;
  background-color: transparent !important;
  padding-bottom: 12px !important;
}

.ui.button.homeButton:hover {
  color:white;
  /* font-weight: 500; */
  transform: scale(1.2);
  /* font-size: 1.1em !important; */
  /* text-decoration: underline; */
}

.ui.button.homeButtonSignup {
  font-weight: 300 !important;
  color:white;
  background-color: #59d759 !important;
}

.ui.button.homeButtonSignup:hover {
  color:white;
  /* font-weight: 500; */
  transform: scale(1.2);
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



.getStartedButton {
  margin-bottom:4em !important;
  background-color: #4DC6E7 !important;
  font-size: 1.2em !important;  
  background-color:transparent !important;
  border:2px solid white !important;
  border-radius:24px !important;
}

.block-paragraph {
  font-size:1.33em;
  font-weight:300;
  padding: top 2.5em;
}

.getStartedButton:hover {
  /* transform: scale(1.2); */
  background-color: #b8e5f1 !important;
}


.typeButton {

  font-size: 1.2em !important;  
  background-color:transparent !important;
  border:2px solid white !important;
  border-radius:24px !important;
  margin: 1em !important;
}


.typeButton:hover {
  /* transform: scale(1.2); */
  background-color: #b8e5f1 !important;
  border:2px solid #296aac !important;
  border-color: #296aac;
}


.animated-header {
  -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 1s; /* Firefox < 16 */
   -ms-animation: fadein 1s; /* Internet Explorer */
    -o-animation: fadein 1s; /* Opera < 12.1 */
       animation: fadein 1s;
       animation-fill-mode: forwards;
       -webkit-animation-fill-mode: forwards;
}

.animated-header.large {
  text-align: center;
font-size: 3em !important;
}

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}
@keyframes fadeout {
  from { opacity: 1; }
  to   { opacity: 0; }
}


.fadeIn {
  -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 1s; /* Firefox < 16 */
   -ms-animation: fadein 1s; /* Internet Explorer */
    -o-animation: fadein 1s; /* Opera < 12.1 */
       animation: fadein 1s;
       animation-fill-mode: forwards;
       -webkit-animation-fill-mode: forwards;
}


.fadeOut {
  -webkit-animation: fadeout 1s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadeout 1s; /* Firefox < 16 */
   -ms-animation: fadeout 1s; /* Internet Explorer */
    -o-animation: fadeout 1s; /* Opera < 12.1 */
       animation: fadeout 1s;
       animation-fill-mode: forwards;
       -webkit-animation-fill-mode: forwards;
       animation-delay: 0.5s;
}

.menuList {
  background-image:linear-gradient(to bottom right, #2D3238 , #424545) !important;
}

.gridHeader {  
  font-size:2em !important;
  padding-top:1em;
  padding-bottom:1.5em !important;
}

.gridContentHeader {
  padding-bottom: 1em;
  padding-left:0.7em;
  color: #013153;
  font-size:2em;
  font-weight:bold
}
.gridText {  
  color:black;
  font-size:1.5em;
  padding-bottom:0.5em;
  padding-left:1em;
  padding-right:1em;
  text-align: left;
}

.gridTextSmall {  
  color:black;
  font-size:1.2em;
  padding-top:0.5em;
  padding-bottom:0;
  padding-left:1em;
  padding-right:1em;
  text-align: left;
}

.gridSection:before {
opacity: .7;
content: '';
position: absolute;
z-index: -1;
width: 100%;
height: 600px;
margin-top: 24rem;
transform: skewY(-20deg);
background: linear-gradient(20deg, #fff 0, #fff 67%, rgba(63,146,209,0.15) 100%);
}
.gridImage {
  position: absolute;
  left:100%;
  top:0;
}

.gridImageLeft {

}

.divider {
  height:4px;
  background-color: #2693ff;
  width: 100%;  
  margin-top: 80px;
  margin-bottom: 80px;
}


.gridImageDivider {
  width:100%;
  min-height: 10em;
  max-width: 1024px;
}

.columnHeader {
  font-size:1.5em;
  font-weight: 500;
  color:black;
  padding-top:1em;
}

.columnText {
  color:black;
  font-size:1.3em;
  padding-bottom:1em;
  padding-top:0.5em;
  text-align: left;
}

.columnCard {
  background-color: white;
  padding:1.3em;
  border-radius:1em;  
  /* border:1px solid #ccc; */
  min-height: 30em;
  min-width:10em;
}

.columnImage {
  width: 90%;
  min-height: 11em;
}


.shadow-5 { 
  /* box-shadow: 0 2px 4px 0 rgba(136,144,195,0.2),0 5px 15px 0 rgba(37,44,97,0.15); */
}

.featureHeader {  
  font-size:2em !important;
  color:white !important;
  /* min-height: 4em; */
}

.featureText {
  font-size:1.3em;
  padding-top:1em;
  padding-left:1em;
  padding-right:1em;
}

.featureImage {
  width:40%;
  min-width:180px;
  min-height: 10em;
}

.footerHome {
  background-color: #013153 !important;
}

.parallax {
  /* The image used */
  background-image: url("/images/austin-jeremy-banks-photo.jpg");

  /* Set a specific height */
  height: 800px;

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.wavy-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 20px; /* Adjust the height to fit your wavy line */
}

.wavy-line {
  position: absolute;
  width: 200%; /* Make it twice the width of the container */
  height: 100%;
  animation: moveWavy 4s linear infinite;
}

.gridImageDivider {
  display: block;
  width: 50%; /* Make it half the width of .wavy-line to fit two copies */
  height: 100%; /* Adjust the height to fit the container */
  object-fit: cover; /* Scale the image proportionally to fill the container's height */
}

/* Add another copy of the wavy line to create a seamless loop */
.wavy-line::before {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  width: 50%;
  height: 100%;
  background: url('/images/yayfun-art-wavy-divider.svg') no-repeat;
  background-size: auto 100%; /* Scale the image proportionally to fill the container's height */
}

@keyframes moveWavy {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}
